<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A
        <number-value :value="massMg" unit="\text{g}" />
        sample of Mg at a temperature of
        <number-value :value="tempMg" unit="^\circ\text{C}" />
        is added to
        <stemble-latex content="$50.0\,\text{g}$" />
        of water at
        <number-value :value="tempInit" unit="^\circ\text{C}" />
        in a coffee cup calorimeter. Assuming that none of the water boils, what is the final
        temperature,
        <stemble-latex content="$\text{T}_\text{f},$" />
        of the water?
      </p>

      <p class="mb-3">
        Note that
        <stemble-latex content="$\text{s} = 1.024\,\text{J/g} ^{\circ}\text{C}$" />
        for Mg, and
        <stemble-latex content="$\text{s} = 4.184\,\text{J/g} ^{\circ}\text{C}$" />
        for
        <stemble-latex content="$\ce{H2O}." />
      </p>

      <calculation-input
        v-model="inputs.studentAnswer"
        prepend-text="$\text{T}_\text{f}:$"
        append-text="$^{\circ}\text{C}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemCPP1210_Task88',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
    };
  },
  computed: {
    massMg() {
      return this.taskState.getValueBySymbol('massMg').content;
    },
    tempMg() {
      return this.taskState.getValueBySymbol('tempMg').content;
    },
    tempInit() {
      return this.taskState.getValueBySymbol('tempInit').content;
    },
  },
};
</script>
